.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.nav-link, .nav-item .dropdown-toggle {
  font-family: 'Baloo 2' !important;
  color: #e4e4e4 !important;
  transition: .1s ease-in-out !important;
  transition-property: color,background-color,border-color,box-shadow !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
  text-decoration: none !important;
  font-size: 15px !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
}

.nav-link:hover, .nav-item .dropdown-toggle:hover {
  color: #b6b6b6 !important;
}

.nav-item .dropdown-toggle::after {
  display: none !important;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.nav-item .dropdown-menu {
  display: block;
  margin-top: 0 !important;
  background: transparent;
  border-radius: 0 !important;
  border: none !important;
}

.nav-item .dropdown-menu .dropdown-item {
  color: #e4e4e4 !important;
  transition: .1s ease-in-out !important;
  transition-property: color,background-color,border-color,box-shadow !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-family: 'Baloo 2' !important;
  font-size: 13px !important;
}

.nav-item .dropdown-menu .dropdown-item:hover {
  background-color: initial;
  color: #b6b6b6 !important;
}

.nav-item .dropdown-menu .dropdown-pl {
  margin: 0;
  padding: 4px 16px 0 16px;
  color: #e4e4e4 !important;
  transition: .1s ease-in-out !important;
  transition-property: color,background-color,border-color,box-shadow !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-family: 'Baloo 2' !important;
  font-size: 13px !important;
}

.nav-item .dropdown-menu .dropdown-p {
  color: #e4e4e4 !important;
  margin: 0;
  padding: 0 16px 4px 16px;
  overflow-wrap: break-word;
  justify-content: center;
}

h1 {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
}

.headline {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600 !important;
  line-height: 1.1 !important;
  font-size: 3.2rem !important;
  color: #3f3f3f !important;
}

.offcanvas.show:not(.hiding) {
  max-width: 75%;
}

.offcanvas-body {
  background: rgba(63, 63, 63, 0.95);
}

.hero-custom {
  background-position: center;
  position: relative;
}

.custom-overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(63, 63, 63, 0.3);
  /* z-index: -1; */
}

@media (min-width: 768px) {
  
}

@media (min-width: 960px) {
  .headline {
    font-size: 4rem !important;
  }
}

@media (min-width: 992px) {
  .offcanvas-body {
    background: transparent;
  }

  .nav-link, .nav-item .dropdown-toggle {
    min-height: 80px;
  }

  .nav-item .dropdown-menu {
    display: none;
    background-color: rgba(63, 63, 63, 0.95);
  }
}

@media (min-width: 1200px) {
  .headline {
    font-size: 6rem !important;
  }
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.second-headline {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 700 !important;
  line-height: 1.1 !important;
  color: #3f3f3f !important;
  /* font-size: 6rem !important; */
}

.second-headline-white {
  color: #ffffff !important;
}

.top-headline {
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  font-size: 16px;
}

.subline {
  font-family: 'Baloo 2' !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
  text-decoration: none !important;
  font-size: 15px !important;
}

.btn-custom {
  display: inline-block;
  background-color: rgba(63, 63, 63, 0.65) !important;
  border: 1px solid #3F3F3F !important;
  color: #FFFFFF !important;
  transition: all 0.2s linear !important;
  font-family: 'Baloo 2' !important;
  font-weight: 700 !important;
  padding: 0 30px !important;
  line-height: 44px !important;
  text-transform: uppercase !important;
}

.btn-custom:hover {
  background-color: rgba(63, 63, 63, 0.95) !important;
}

p {
  font-family: 'Open Sans', sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

p {
  font-size: 1rem;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}